.card {
    margin-top: 40px;
    min-height: 815px;
    border-radius: 10px;
}

.card,
.title,
.subtitle,
.content h4 {
    color: #1B1C22;
}

.card img {
    color: rgb(26, 47, 71);
    color: radial-gradient(
        circle,
        rgba(26, 47, 71, 1) 0%,
        rgba(26, 47, 71, 1) 100%
    );
    opacity: 0.6;
    transition: 0.4s;
    border-radius: 10px 10px 0px 0px;
}

.card img:hover {
    opacity: 1;
    transition: 0.3s;
    cursor: pointer;
    transform: translateY(-8px);
}

.content-list {
    padding-top: 20px;
    padding-bottom: 30px;
}

.content-list ul {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.webcard-button {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    padding: 20px;
    position: absolute;
    bottom: 30px;
    text-align: center;
    background-color: transparent;
    border-color: #B91646;
    color: #B91646;
    transition: 0.3s;
}

.webcard-button:hover {
    border-color: #5D163F;    ;
    background-color: #DFD8C9;
    color: #5D163F;
    transition: 0.2s;
}

@media screen and (max-width: 768px) {
    .content {
        padding-bottom: 20px;
    }
    .card img {
        opacity: 1;
    }
}


/* ---------------------------------------------  */
/* ---------------- 3D BUTTON ----------------  */
/* ---------------------------------------------  */

.threed-button-container{
    display: flex;
    justify-content: center;
    height:80px;
    position: absolute;
    bottom: 20px;
    width: 80%
}

.pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
  }
  .shadow {
    position: absolute;
    top: 24px;
    left: 0;
    width: 100%;
    height: 45px;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }
  .edge {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    background: linear-gradient(
      to left,
      hsl(340deg 100% 16%) 0%,
      rgb(105, 0, 35) 8%,
      rgb(105, 0, 35) 8%,      
      hsl(340deg 100% 16%) 100%
    );
  }
  .front {
    display: block;
    font-family: "bebas_neue";
    position: relative;
    padding: 12px 42px;
    border-radius: 12px;
    font-size: 1.25rem;
    color: white;
    height: 45px;
    background: #B91646;
    will-change: transform;
    transform: translateY(-4px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }
  .pushable:hover {
    filter: brightness(110%);
  }
  .pushable:hover .front {
    transform: translateY(-6px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }
  .pushable:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
  .pushable:hover .shadow {
    transform: translateY(4px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }
  .pushable:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }
  .pushable:focus:not(:focus-visible) {
    outline: none;
  }