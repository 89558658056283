.github-iconSVG {
    transform: translate(214px, 254px);
    color: #B91646;
    opacity: 0.8;
    transition: 0.3s;
}

.github-iconSVG:hover {
    opacity: 1;
    color: #B91646;
    transition: 0.2s;
}

.email-iconSVG {
    color: #B91646;
    opacity: 0.7;
    transition: 0.3s;
}

.email-iconSVG:hover {
    opacity: 1;
    color: #B91646;
    transition: 0.2s;
}

.linkedin-iconSVG {
    transform: translate(232px, 190px);
    color: #B91646;
    opacity: 0.8;
    transition: 0.3s;
}

.linkedin-iconSVG:hover {
    opacity: 1;
    color: #B91646;
    transition: 0.2s;
}

/* ---------------------------------------------  */
/* ---------------- MOBILE VIEW ----------------  */
/* ---------------------------------------------  */

@media screen and (max-width: 768px) {
    .contact-SVG {
        padding-top: 250px;
        /* padding-bottom: 600px; */
        display: block;
    }
}
