html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: "bebas_neue";
    src: local("bebas_neue"),
     url("./fonts/bebas_neue/BebasNeue-Regular.ttf") format("truetype");
    font-weight: bold;
    }

@font-face {
    font-family: "brittany_signature";
    src: local("brittany_signature"),
     url("./fonts/brittany_signature/BrittanySignature.ttf") format("truetype");
    font-weight: bold;
    }

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fffdfa;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
