/* .landing {
    padding-top: 150px;
    padding-right: 0px;
    padding-bottom: 150px;
    padding-left: 0px;
} */
/* .logo-landing {
    animation: logo_load 700ms cubic-bezier(0.25, 0.1, 0.25, 1);
} */

.landing-section {
    height: 1000px;
    padding-top: 70px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-section-title{
    display: grid;
    justify-items: center;
    grid-template-rows: 0.5fr 0.8fr 0.3fr 0.5fr;
}


.landing-section-michael{
    font-family: "brittany_signature";
    font-size: 140px;
    color: #B91646;
    z-index: 1;

}

.landing-section-moawad{
    font-family: "bebas_neue";
    font-size: 180px;
}

.landing-section-box{
    /* background-color: #DFD8C9; */
    height: 80px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 350px;
    align-items: center;
    /* border-radius: 10px;
    border: 2px solid; */
}

.landing-section-boxtext{
    font-size: 30px;
    font-family: "bebas_neue";
}

.title-landing {
    font-size: 90px;
    text-align: left;
}

.one {
    font-weight: 900;
    font-size: 100px;
    color: #1B1C22;
}

.two {
    font-weight: 900;
    font-size: 22px;
    color: rgba(255, 255, 255, 0.8);
}

.landing-text-1 {
    padding-top: 100px;
    position: absolute;
}
.landing-text-2 {
    /* animation-fill-mode: forwards; */
    padding-top: 100px;
    padding-left: 40px;
    /* position: absolute; */
}

.logo-landing {
    padding-top: 20px;
    height: 184px;
    /* animation: logo_load 700ms cubic-bezier(0.25, 0.1, 0.25, 1); */
}

/* .landing-image {
    animation: dreamer_load 700ms cubic-bezier(0.25, 0.1, 0.25, 1) 700ms;
} */

#cloud_1 {
    animation: cloud_close 20s ease infinite alternate;
}
#cloud_2 {
    animation: cloud_close 33s ease infinite alternate;
}
#cloud_3 {
    animation: cloud_far 40s ease infinite alternate;
}

@keyframes cloud_close {
    from {
        transform: translateX(-300px);
    }
    to {
        transform: translateX(0px);
    }
}

@keyframes cloud_far {
    from {
        transform: translateX(-200px);
    }
    to {
        transform: translateX(100px);
    }
}
/* ---------------------------------------------  */
/* ---------------- ANIMISTA LOGO ----------------  */
/* ---------------------------------------------  */

.landing-text-1 {
    -webkit-animation: rotate-in-2-cw 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both 500ms;
    animation: rotate-in-2-cw 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
        500ms;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-9-11 18:1:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-2-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-cw {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}
@keyframes rotate-in-2-cw {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

/* ---------------------------------------------  */
/* ---------------- ANIMISTA TEXT ----------------  */
/* ---------------------------------------------  */

.landing-text-2 {
    /* position: absolute; */
    -webkit-animation: tracking-in-expand 0.7s
        cubic-bezier(0.215, 0.61, 0.355, 1) 600ms both;
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 800ms
        both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-9-11 17:15:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

/* ---------------------------------------------  */
/* ---------------- ANIMISTA IMAGE ----------------  */
/* ---------------------------------------------  */

.landing-image {
    position: absolute;
    -webkit-animation: tilt-in-bottom-2 0.6s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: tilt-in-bottom-2 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-9-11 18:4:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-bottom-2
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-bottom-2 {
    0% {
        -webkit-transform: rotateY(-30deg) translateY(300px) skewY(30deg);
        transform: rotateY(-30deg) translateY(300px) skewY(30deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
        transform: rotateY(0deg) translateY(0) skewY(0deg);
        opacity: 1;
    }
}
@keyframes tilt-in-bottom-2 {
    0% {
        -webkit-transform: rotateY(-30deg) translateY(300px) skewY(30deg);
        transform: rotateY(-30deg) translateY(300px) skewY(30deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
        transform: rotateY(0deg) translateY(0) skewY(0deg);
        opacity: 1;
    }
}



/* ----------------------------------------------
 * Generated by Animista on 2022-7-20 11:52:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */

 .slide-in-blurred-bottom {
	-webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both 1000ms;
}

 @-webkit-keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
              transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
              transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }

  
  /* ----------------------------------------------
  * Generated by Animista on 2022-7-20 11:55:43
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */
  
  /**
  * ----------------------------------------
  * @animation flicker-in-1
  * ----------------------------------------
  */

  
.flicker-in-1 {
-webkit-animation: flicker-in-1 2s linear both;
        animation: flicker-in-1 2s linear both;
}
 @-webkit-keyframes flicker-in-1 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes flicker-in-1 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  

/* ---------------------------------------------  */
/* ---------------- MOBILE VIEW ----------------  */
/* ---------------------------------------------  */

@media screen and (max-width: 768px) {
    .column-landing {
        position: absolute;
        top: 50px;
    }
    .landing-image {
        display: none;
    }
    .landing-section {
        height: 555px;
    }

    .logo-landing {
        display: none;
    }
    .one {
        font-size: 60px;
        padding-bottom: 60px;
    }

    .two {
        font-size: 18px;
    }
    .landing-text-2 {
        /* display: block; */
        /* position: absolute; */
        /* animation-fill-mode: forwards;
        opacity: 0; */
        padding-top: 30px;
        padding-left: 0px;
    }
    .landing-section-title{
        padding-top: 200px;
    }
    .landing-section-michael{
        font-size: 80px;
    }
    .landing-section-moawad {
        font-size: 100px;
    }

    .landing-section-box {
        /* background-color: #DFD8C9; */
        height: 50px;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 200px;
        align-items: center;
        /* border-radius: 10px;
        border: 2px solid; */
    }
    .landing-section-boxtext{
        font-size: 20px;
    }
}




/* ----------------------------------------------
* Generated by Animista on 2020-9-12 11:54:3
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation swing-in-top-fwd
* ----------------------------------------
*/

.swing-in-top-fwd {
    -webkit-animation: swing-in-top-fwd 0.5s
      cubic-bezier(0.175, 0.885, 0.32, 1.275) both 1200ms;
    animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both
      1200ms;
  }

  @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }


  /* ----------------------------------------------
 * Generated by Animista on 2022-7-20 12:0:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */

.fade-in {
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both 2200ms;
}

@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  

/* ---------------------------------------------  */
/* ---------------- 3D BUTTON ----------------  */
/* ---------------------------------------------  */

.landing-section-btncontainer{
    display: flex;
    justify-content: center;
    height:80px;
}

.pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
  }
  .shadow {
    position: absolute;
    top: 24px;
    left: 0;
    width: 100%;
    height: 45px;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }
  .edge {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    background: linear-gradient(
      to left,
      hsl(340deg 100% 16%) 0%,
      rgb(105, 0, 35) 8%,
      rgb(105, 0, 35) 8%,      
      hsl(340deg 100% 16%) 100%
    );
  }
  .front {
    display: block;
    font-family: "bebas_neue";
    position: relative;
    padding: 12px 42px;
    border-radius: 12px;
    font-size: 1.25rem;
    color: white;
    height: 45px;
    background: #B91646;
    will-change: transform;
    transform: translateY(-4px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }
  .pushable:hover {
    filter: brightness(110%);
  }
  .pushable:hover .front {
    transform: translateY(-6px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }
  .pushable:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
  .pushable:hover .shadow {
    transform: translateY(4px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }
  .pushable:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }
  .pushable:focus:not(:focus-visible) {
    outline: none;
  }
