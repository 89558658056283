.social-links-section {
    position: fixed;
}

svg {
    width: 100%;
    height: 100%;
    fill: currentcolor;
    vertical-align: middle;
}
