.landing-icon-SVGs {
    display: none;
}

/* ---------------------------------------------  */
/* ---------------- MOBILE VIEW ----------------  */
/* ---------------------------------------------  */

@media screen and (max-width: 768px) {
    .landing-icon-SVGs {
        padding-top: 300px;
        padding-bottom: 600px;
        display: block;
    }
}
