.scroll-test {
    scroll-behavior: smooth;
    background: #F4EDE1;
}

.App {
    padding-right: 100px;
    /* padding-bottom: 200px; */
    padding-left: 100px;
    scroll-behavior: smooth;
    /* background: #F4EDE1; */
    color: #1B1C22;
}

/* 
.tile article {
    transform: translatex(10px);
} */

.tile article{
    /* border: 2px solid black;*/
    border-radius: 10px; 
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);;
}


.homepage-button {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    padding: 20px;
    position: fixed;
    bottom: 30px;
    left: 40px;
    text-align: center;
    background-color: transparent;
    border-color: #00d1b2;
    color: #00d1b2;
    transition: 0.3s;
}

.homepage-button:hover {
    background-color: #3f3d56;
    color: #00d1b2;
    transition: 0.2s;
}

h1 {
    font-family: "bebas_neue";
    font-size: 80px;
    font-weight: 900;
    padding-bottom: 20px;
}

.section {
    padding-top: 150px;
    padding-right: 0px;
    padding-bottom: 180px;
    padding-left: 0px;
}

.test-color {
    background-color: #DFD8C9;
}

.title {
    padding-bottom: 10px;
}

.title-list {
    color: #1B1C22;
    padding-top: 20px;
}

.tile h3 {
    font-size: 22px;
}

.tile p {
    font-size: 17px;
}

.button {
    padding: 20px;
}

/* ---------------------------------------------  */
/* ---------------- MOBILE VIEW ----------------  */
/* ---------------------------------------------  */

@media screen and (max-width: 768px) {
    .App {
        padding-right: 28px;
        padding-bottom: 200px;
        padding-left: 28px;
    }
    h1 {
        font-size: 50px;
    }
    .section {
        padding-top: 100px;
    }
}
