.social-icons-leftpanel {
    /* display: flex;
    flex-direction: column; */
    width: 80px;
    /* height: 80px; */
    position: sticky;
    bottom: 20px;
    padding-left: 45px;
    /* padding-right: 45px; */
    padding-bottom: 30px;
}

.social-icons-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 130px;
}

.social-icons-leftpanel ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin: 0px;
    list-style: none;
}

/* ---------------------------------------------  */
/* ---------------- ANIMISTA TEXT ----------------  */
/* ---------------------------------------------  */

.social-list-item-one {
    width: 20px;
    height: 20px;
    -webkit-animation: swing-in-left-fwd 0.5s
        cubic-bezier(0.175, 0.885, 0.32, 1.275) both 1500ms;
    animation: swing-in-left-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
        both 1500ms;
}

.social-list-item-two {
    width: 50px;
    height: 20px;
    -webkit-animation: swing-in-left-fwd 0.5s
        cubic-bezier(0.175, 0.885, 0.32, 1.275) both 1600ms;
    animation: swing-in-left-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
        both 1600ms;
}

.social-list-item-three {
    width: 20px;
    height: 20px;
    -webkit-animation: swing-in-left-fwd 0.5s
        cubic-bezier(0.175, 0.885, 0.32, 1.275) both 1600ms;
    animation: swing-in-left-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
        both 1600ms;
}

.social-list-item-four {
    width: 20px;
    height: 20px;
    -webkit-animation: swing-in-left-fwd 0.5s
        cubic-bezier(0.175, 0.885, 0.32, 1.275) both 1700ms;
    animation: swing-in-left-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
        both 1700ms;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-9-12 12:10:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-left-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-left-fwd {
    0% {
        -webkit-transform: rotateY(100deg);
        transform: rotateY(100deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 1;
    }
}
@keyframes swing-in-left-fwd {
    0% {
        -webkit-transform: rotateY(100deg);
        transform: rotateY(100deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    .social-icons-leftpanel {
        display: none;
    }
}
