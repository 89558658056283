.footer-section {
    background-color: #F4EDE1;
    padding-bottom: 30px;
    color: rgba(255, 255, 255, 0.473);
    /* color: #1B1C22; */
}

/* ---------------------------------------------  */
/* ---------------- MOBILE VIEW ----------------  */
/* ---------------------------------------------  */

@media screen and (max-width: 768px) {
    .footer-text {
        font-size: 12px;
    }
}
